import { getExternalLink } from 'cadenza/api-client/external-links-api';
import { getLogger } from 'cadenza/utils/logging';
import { isFeatureAvailable } from 'cadenza/features';

const logger = getLogger('cadenza/integration/post-message');

if (isFeatureAvailable('CADENZA_JS_SANDBOX')) {
  logger.enableAll();
}

/**
 * @see Published {@link https://jira.disy.net/browse/CADENZA-32861|CADENZA-32861} This type is also defined in `cadenza.js`.
 */
export type CadenzaEvent<T = undefined> = T extends undefined ? { type: string } : { type: string; detail: T };

/**
 * Post an event to a customer application.
 *
 * The event is sent using `postMessage()` to the `parent` window. By default, the `location.origin` is used as the target origin.
 * If a `webApplication` URL parameter with the ID of an external link is present, the origin is taken form the external link.
 *
 * @param type - The event type
 * @param [detail] - The event detail
 */
export function postEvent (type: string, detail?: unknown) {
  // eslint-disable-next-line promise/catch-or-return
  getTargetOrigin().then((targetOrigin) => {
    const event = { type, detail };
    logger.log(`postEvent() to ${targetOrigin}`, event);
    window.parent.postMessage(event, targetOrigin);
  });
}

const subscriptions: ([string, (event: CadenzaEvent<never>) => void])[] = [];

/**
 * Subscribe to events from a customer application.
 *
 * @param type - The event type
 * @param subscriber - The subscriber function
 * @return An unsubscribe function
 */
export function subscribeToEvent<T = unknown> (type: string, subscriber: (event: CadenzaEvent<T>) => void): () => void {
  if (subscriptions.length === 0) {
    window.addEventListener('message', __onMessage__);
  }
  subscriptions.push([ type, subscriber ]);
  return () => {
    subscriptions.forEach(([ subscriptionType, subscriptionSubscriber ], i) => {
      if (subscriptionType === type && subscriptionSubscriber === subscriber) {
        subscriptions.splice(i, 1);
      }
    });
    if (subscriptions.length === 0) {
      window.removeEventListener('message', __onMessage__);
    }
  };
}

// Exported for testing
export async function __onMessage__ (event: MessageEvent<CadenzaEvent>) {
  logger.log('Received message', event);

  const targetOrigin = await getTargetOrigin();
  if (event.origin !== targetOrigin) {
    return;
  }

  const cadenzaEvent = event.data;
  subscriptions.forEach(([ type, subscriber ]) => {
    if (type === cadenzaEvent.type) {
      subscriber(cadenzaEvent as never);
    }
  });
}

async function getTargetOrigin () {
  const webApplication = window.Disy.webApplication;
  if (webApplication) {
    if (webApplication === '*') {
      return '*';
    }
    try {
      const externalLink = await getExternalLink(webApplication);
      return new URL(externalLink.urlPart).origin;
    } catch (error) {
      logger.error(`Could not resolve origin for given external link: ${webApplication}. `
        + 'Maybe the link does not exist or the user has no view privilege for it?', error);
    }
  }
  return location.origin;
}
