import type { TableData, TableParams } from 'cadenza/api-client/tables-api';
import { tableParams } from 'cadenza/api-client/tables-api';
import { ajax } from 'cadenza/api-client/api';
import { MIMETYPE_JSON } from 'cadenza/model/media-type';
import type { RepositoryName } from 'cadenza/repository/model/repository-name';
import type { ExternalBaseLinkId } from 'cadenza/repository/model/external-base-link-id';
import type { ExternalLinkDrillThroughName } from 'cadenza/repository/model/external-link-drill-through-name';
import type { ExternalLinkExtensionName } from 'cadenza/repository/model/external-link-extension-name';
import type {
  DrillThroughType,
  ExternalBaseLinkDto,
  ExternalDrillThroughDto,
  ExternalLinkExtensionDto,
  MutableExternalBaseLinkDto,
  MutableExternalDrillThroughDto,
  MutableExternalLinkExtensionDto
} from 'cadenza/admin/external-links/external-link-dto';

export function getVisibleExternalLinks (
  includeExtensions: boolean,
  params: TableParams = { offset: 0, limit: 500 }) {
  const requestParams = tableParams(params);
  if (includeExtensions) {
    requestParams.set('includeExtensions', 'true');
  }
  return ajax<TableData<ExternalBaseLinkDto>>(`/externallinks?${requestParams}`, {
    dataType: 'json'
  });
}

export function getExternalLink ([ repositoryName, baseLinkName ]: ExternalBaseLinkId) {
  return ajax<ExternalBaseLinkDto>(`/repositories/${repositoryName}/externallinks/${baseLinkName}`);
}

export function createExternalLink (
  repositoryName: RepositoryName,
  externalBaseLinkDto: MutableExternalBaseLinkDto
) {
  return ajax<ExternalBaseLinkDto>(`/repositories/${repositoryName}/externallinks/`, {
    type: 'POST',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(externalBaseLinkDto)
  });
}

export function updateExternalLink (
  [ repositoryName, baseLinkName ]: ExternalBaseLinkId,
  details: Partial<MutableExternalBaseLinkDto>
) {
  const patch = { baseLinkName, repositoryName, ...details };
  return ajax<ExternalBaseLinkDto>(`/repositories/${repositoryName}/externallinks/${baseLinkName}`, {
    type: 'PATCH',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(patch)
  });
}

export function deleteExternalLink ([ repositoryName, baseLinkName ]: ExternalBaseLinkId) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${baseLinkName}`, {
    type: 'DELETE'
  });
}

export async function getVisibleExternalLinkExtensions ([ repositoryName, baseLinkName ]: ExternalBaseLinkId) {
  return ajax<ExternalLinkExtensionDto[]>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/extensions`);
}

interface BaseLinkWithExtensionPair {
  baseLink: ExternalBaseLinkDto;
  extension: ExternalLinkExtensionDto;
}

export async function getExternalLinkExtensionOrThrow (baseLinkId: ExternalBaseLinkId, extensionName: ExternalLinkExtensionName): Promise<BaseLinkWithExtensionPair> {
  const baseLink = await getExternalLink(baseLinkId);
  const extension = baseLink.extensions
    .find(ext => ext.linkExtensionName === extensionName);
  if (!extension) {
    throw new Error(`Failed to fetch External link extension "${extensionName}"`);
  }
  return { baseLink, extension };
}

export function createExternalLinkExtension (
  [ repositoryName, baseLinkName ]: ExternalBaseLinkId,
  externalLinkExtensionDto: MutableExternalLinkExtensionDto
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/extensions/`, {
    type: 'POST',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(externalLinkExtensionDto)
  });
}

export function updateExternalLinkExtension (
  [ repositoryName, baseLinkName ]: ExternalBaseLinkId,
  linkExtensionName: ExternalLinkExtensionName,
  details: ExternalLinkExtensionDto
) {
  return ajax<ExternalLinkExtensionDto>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/extensions/${linkExtensionName}`, {
    type: 'PUT',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(details)
  });
}

export function deleteExternalLinkExtension (
  [ repositoryName, baseLinkName ]: ExternalBaseLinkId,
  linkExtensionName: ExternalLinkExtensionName
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/extensions/${linkExtensionName}`, {
    type: 'DELETE'
  });
}

export function createExternalLinkDrillThrough (
  [ repositoryName, baseLinkName ]: ExternalBaseLinkId,
  externalLinkDrillThroughDto: MutableExternalDrillThroughDto
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/drillthroughs/`, {
    type: 'POST',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(externalLinkDrillThroughDto)
  });
}

export function getExternalLinkDrillThroughs<T extends DrillThroughType> ([ repositoryName, baseLinkName ]: ExternalBaseLinkId, type: T) {
  return ajax<ExternalDrillThroughDto<T>[]>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/drillthroughs?type=${type}`);
}

export function updateExternalLinkDrillThrough (
  [ repositoryName, baseLinkName ]: ExternalBaseLinkId,
  linkDrillThroughName: ExternalLinkDrillThroughName,
  details: MutableExternalDrillThroughDto
) {
  return ajax<ExternalDrillThroughDto<'url'>>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/drillthroughs/${linkDrillThroughName}`, {
    type: 'PUT',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(details)
  });
}

export function deleteExternalLinkDrillThrough (
  [ repositoryName, baseLinkName ]: ExternalBaseLinkId,
  linkDrillThroughName: ExternalLinkDrillThroughName
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${baseLinkName}/drillthroughs/${linkDrillThroughName}`, {
    type: 'DELETE'
  });
}
